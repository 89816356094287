<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="500px"
    @close="close"
  >
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="form.roleName" />
      </el-form-item>
      <el-form-item label="角色码" prop="roleKey">
        <el-input v-model="form.roleKey" />
      </el-form-item>

      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="false">正常</el-radio>
          <el-radio :label="true">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="角色顺序" prop="sortNum">
        <el-input v-model="form.sortNum" type="number" />
      </el-form-item>
      <el-form-item label="菜单">
        <div class="vab-tree-border">
          <el-tree
            ref="treeRef"
            :data="list"
            :default-checked-keys="form.menuIdList"
            :default-expanded-keys="form.menuIdList"
            node-key="menuId"
            show-checkbox
          >
            <template #default="{ data }">
              <span>{{ data.meta.title }}</span>
            </template>
          </el-tree>
        </div>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model="form.remarks" />
      </el-form-item>

      <!-- <el-form-item label="按钮权限">
        <el-checkbox-group v-model="form.btnRolesCheckedList">
          <el-checkbox
            v-for="item in btnRoles"
            :key="item.value"
            :label="item.value"
          >
            {{ item.lable }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item> -->
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  import { addRole, updateRole, getRoleDetail } from '@/api/roleManagement'
  import { getList } from '@/api/router'
  import { useStore } from 'vuex'

  export default defineComponent({
    name: 'RoleManagementEdit',
    emits: ['fetch-data'],
    setup(props, { emit }) {
      const { proxy } = getCurrentInstance()

      const state = reactive({
        formRef: null,
        treeRef: null,
        checkMenu: [],
        form: {
          btnRolesCheckedList: [],
          menuIdList: ['/'],
          roleType: 'CUSTOM',
          sortNum: 0,
        },
        rules: {
          roleName: [
            { required: true, trigger: 'blur', message: '请输入角色名称' },
          ],
          roleKey: [
            { required: true, trigger: 'blur', message: '请输入角色码' },
          ],
          status: [
            { required: true, trigger: 'change', message: '请输入角色码' },
          ],
          sortNum: [
            { required: true, trigger: 'blur', message: '请输入角色顺序' },
          ],
        },
        title: '',
        dialogFormVisible: false,
        list: [],
        /* btnRoles demo */
        btnRoles: [
          {
            lable: '读',
            value: 'read:system',
          },
          {
            lable: '写',
            value: 'write:system',
          },
          {
            lable: '删',
            value: 'delete:system',
          },
        ],
      })

      const store = useStore()
      const showEdit = async (row) => {
        fetchData()
        if (!row) {
          state.title = '添加'
        } else {
          state.title = '编辑'
          state.form = Object.assign({}, row)
          console.log(state.form)
        }
        state.dialogFormVisible = true
        setTimeout(() => {
          handleTreeShow()
        }, 300)
      }
      /**
       * @description: 控制树回显示
       * @return {*}
       */
      const handleTreeShow = () => {
        const role = []
        if (
          state.form.menuIdList == undefined ||
          state.form.menuIdList == 'undefined'
        ) {
          return false
        }
        state.form.menuIdList.forEach((f) => {
          //为了解决父级没有全选中（直选中某个子级）但是数据存在父级id然后回显选中问题
          if (
            !state['treeRef'].getNode(f).childNodes ||
            !state['treeRef'].getNode(f).childNodes.length
          ) {
            role.push(f)
          }
        })
        state['treeRef'].setCheckedKeys(role)
      }
      const close = () => {
        state['formRef'].resetFields()
        state.form = {
          btnRolesCheckedList: [],
        }
        state.dialogFormVisible = false
      }
      const fetchData = async () => {
        const { data } = await getList()
        state.list = data
      }
      const save = () => {
        state['formRef'].validate(async (valid) => {
          if (valid) {
            const tree = state['treeRef'].getCheckedNodes(false, true)
            const treeObject = Array.from(tree, ({ menuId }) => menuId)
            let userInfo = store.getters['user/user']
            let msg = ''
            if (state.title == '编辑') {
              state.form.menuIdList = [...treeObject]
              state.form.sysCode = 'mehz'
              state.form.tid = userInfo.tid
              state.form.roleType = 'CUSTOM'
              let data = await updateRole(state.form)
              msg = data.msg
            } else {
              state.form.menuIdList = [...treeObject]
              state.form.sysCode = 'mehz'
              state.form.tid = userInfo.tid
              state.form.roleType = 'CUSTOM'
              let data = await addRole(state.form)
              msg = data.msg
            }
            // const { msg } = await doEdit({
            //   ...state.form,
            //   ...treeObject,
            // })
            proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
            emit('fetch-data')
            close()
          }
        })
      }
      return {
        ...toRefs(state),
        showEdit,
        close,
        fetchData,
        save,
      }
    },
  })
</script>

<style lang="scss" scoped>
  .vab-tree-border {
    height: 200px;
    padding: $base-padding;
    overflow-y: auto;
    border: 1px solid #dcdfe6;
    border-radius: $base-border-radius;
  }
</style>
